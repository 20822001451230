import React, { useState } from 'react';
import styled from 'styled-components';
import { BloxAnchor, BloxText, useStatus, ProductImage } from '@pixleeturnto/wr4pt';
import type { FetchCampaignInfluencerResult } from 'apis';
import { CampaignInfluencerLinkMethods, ProductMethods } from '@pixleeturnto/wr4pt/dist/models';

export const CampaignLinkItem = ({ campaignLink }: { campaignLink: FetchCampaignInfluencerResult['campaign_influencer_links'][number]; }) => {
  const { showError } = useStatus();
  const [isCopied, setIsCopied] = useState(false);

  const onCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(campaignLink.link ?? '');
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000);
    } catch (err) {
      showError('The copy failed, please ensure you have the right permissions.');
    }
  };

  const product = campaignLink.campaign_product.product;

  return (
    <>
      <Container>

        {campaignLink.link_type === 'destination' ?
          <BloxText $cname="slate5" $w="100%" $fs_md $bold>Brand tracking link:</BloxText> :
          <ProductContainer>
            <ProductImage photoUrl={ProductMethods.getPhotoUrl(product) ?? ''} productName={ProductMethods.getName(product) ?? ''} $w={58} />
            <ProductInfo>
              <ProductTitle $cname="slate5" $fs_lg $bold>{ProductMethods.getName(product)}</ProductTitle>
              <ProductUrl href={CampaignInfluencerLinkMethods.getLinkUrl(campaignLink) ?? ''} target="_blank" rel="noopener noreferrer">{CampaignInfluencerLinkMethods.getLinkUrl(campaignLink)}</ProductUrl>
              <ProductSkuAndPricing>
                <BloxText $cname="slate2" $fs_sm $pr={3}>{product?.sku} - </BloxText>
                <BloxText $cname="slate2" $fs_sm $bold $pl={3}>{ProductMethods.getFormattedPrice(product)}</BloxText>
              </ProductSkuAndPricing>
            </ProductInfo>
          </ProductContainer>
        }

        <CampaignLinkContainer>
          {campaignLink.link}
          <CopyLinkButton onClick={onCopyClick}>
            {isCopied ? <BloxText $cname="blue" $fs_sm $bold $pl={3}>Copied!</BloxText> :
              <>
                <i className="fa-light fa-copy"></i>
                <BloxText $cname="blue" $fs_sm $bold $pl={3}>Copy</BloxText>
              </>
            }
          </CopyLinkButton>
        </CampaignLinkContainer>

      </Container>
      <CampaignLinkDivider />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 17px;
`;

// length of photo is 58px + 17px margin
const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  margin-left: 17px;
`;

const ProductUrl = styled(BloxAnchor)`
  font-size: var(--blox-font-size-sm) !important;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ProductTitle = styled(BloxText)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ProductSkuAndPricing = styled.div`
  display: flex;
  align-items: center;
`;

const CampaignLinkDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--blox-slate1); 
`;

const CampaignLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 2px solid var(--blox-slate1);
  border-radius: 3px;
  padding: 8px 10px 8px 14px;
  height: 38px;
  font-size: 14px;
  font-family: var(--blox-font-family);
  color: var(--blox-slate5);
`;

const CopyLinkButton = styled.button`
  background-color: white;
  height: auto;
  width: auto;
  color: var(--blox-blue);
  text-transform: none;
  padding: 0;
`;
